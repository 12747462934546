import React, {useState, useEffect} from 'react'
import {Link, navigate} from 'gatsby'
import {Image, Item } from 'semantic-ui-react'
import AddToCart from '../AddToCart'
import Prompt from '../Prompt'
import ImageCarousel from '../Carousel/imageCarousel'
import VariantDropdown from './variantDropdown'
import OptionDropdown from './optionDropdown'
import * as styles from './styles/productSum.module.css'
import './styles/productSum.css'

export default ({
	id,
	name,
	meta,
	sku,
	orgsku,
	url,
	mainImageHref,
	organizationName,
	organizationUrl,
	images,
	variant,
	promo,
	disabled,
	loading,
	options,
	location
}) => {
	const [itemnote, setItemNote] = useState(options !== undefined ? (options.length > 1 ? undefined: (options.length > 0 ? options[0].name: "")) : "")
	const [showConfirm, setShowConfirm] = useState(false)
	const [activeImage, setActiveImage] = useState(mainImageHref)
	const [convertedImages, setConvertedImages] = useState(images.map((item)=>{
		return {
			squareImageHref:item.thumbnail,
			mainImageHref:item.url,
		}
	}));

	const showPrompt = (quantity) =>{
		setShowConfirm(true)
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			'event': 'addToCart',
			'ecommerce': {
				'add': {
					'value': parseFloat(meta.display_price.with_tax.amount)*parseInt(quantity, 10),
					'products': [
						{
							'name': name,
							'price': meta.display_price.with_tax.amount,
							'id': sku,
							'quantity':parseInt(quantity, 10),
							'item_id': sku,
							'item_name': name,
							'currency': "PHP",
						}
					]
				}
			}
		});
	}

	useEffect(()=>{
		let temp = images.map((item)=>{
			return {
				squareImageHref:item.thumbnail,
				mainImageHref:item.url,
			}
		})
		setConvertedImages(temp);
	}, [images])

	const hidePrompt = () => {
		setShowConfirm(false)
	}


	const changeOption = (newnote) =>{
		setItemNote(newnote)
	}

	const changeMainImage = ({mainImageHref}) =>{
		setActiveImage(mainImageHref)
	}


	const closePrompt = () => {
		/*check in case there's no state being passed
		 *by link/navigate as in user directly went to this product page by direct url
		 */
		 /*
		 // 2020-10-02 - Try continue shopping to show related sections
		if(location.state) {

			if(location.state.cameFrom) {
				const page = location.state.cameFrom.split('-')[0]
				//not sure if order page is back button or home page or something else
				if( location.state.cameFrom && page!=="cart") {
					window.history.back();
				}else {
					navigate('/');
				}
			}else {
				navigate('/');
			}

		}else {
			navigate('/');
		}
		*/
		 // 2020-10-02 - Try continue shopping to show related sections
		setShowConfirm(false)
		document.getElementById('page-related').scrollIntoView({
			behavior: 'smooth'
		});

		//window.scrollTo({top: 0, behavior: 'smooth'});
	}
	let filteredVariant
	let currentItem
	if(variant!==undefined) {
		currentItem = variant.filter((item)=>{
			return url===item.url
		})
		filteredVariant = variant.filter((item)=>{
			return url!==item.url
		})
	}
	return (
		<React.Fragment>
			<Item.Group>
				<Item className={styles.item}>
					<Item.Image size="medium">
						<Image src={activeImage} alt={name} />
						{ convertedImages.length > 1 && (
							<div className={styles.desktopOnlyMod}>
								<ImageCarousel
									items={convertedImages}
									onImageHover={changeMainImage}
									imagesAsLinks={false}
									navButtonStyle={`${styles.imgCarouselNav} ${styles.imgCarouselDesktopNav}`}
									showHeader={false}
									imageClassName={styles.imageCarouselClassName}
									selectedImageClassName={styles.selectedImage}
									selectedImage={activeImage}
									squareAsSrc={true}
								/>
							</div>

						)}
					</Item.Image>
					<Item.Content>
						<div className={styles.mobileOnlyMod} >
							{ convertedImages.length > 1 && (
								<ImageCarousel
									items={convertedImages}
									onImageClick={changeMainImage}
									onImageHover={changeMainImage}
									imagesAsLinks={false}
									navButtonStyle={`${styles.imgCarouselNav} ${styles.imgCarouselMobileNav}`}
									showHeader={false}
									mobileShown={4}
									imageClassName={styles.imageCarouselClassName}
									selectedImage={activeImage}
									selectedImageClassName={styles.selectedImage}
								/>
							)}
						</div>
						<Item.Header className={styles.header}><h1>{name}</h1></Item.Header>
						<Item.Description className={styles.text}>
							<div>
								{`${meta.display_price.with_tax.currency}${meta.display_price.with_tax.formatted}`}
							</div>
							{filteredVariant!==undefined && (
								<React.Fragment>
									{filteredVariant.length > 0 && (
										<div className={styles.dropdown}>
											<VariantDropdown promoItem={false} items={filteredVariant} currentItem={currentItem} productName={name}/>
										</div>
									)}
								</React.Fragment>
							)}
							{options!==undefined && (
								<React.Fragment>
									{options.length > 1 && (
										<div className={styles.dropdown}>
											<OptionDropdown updateOption={changeOption} items={options}/>
										</div>
									)}
								</React.Fragment>
							)}
							{promo!==undefined && (
								<React.Fragment>
									{promo.length > 0 && (
										<div className={styles.dropdown}>
											<VariantDropdown promoItem={true} items={promo} currentItem={currentItem} productName={name}/>
										</div>
									)}
								</React.Fragment>
							)}
							Supplier : <Link to={`/organization/${organizationUrl}/`} className={styles.link}>
								{organizationName}

							</Link>
							{(orgsku.length < 2 && organizationUrl !== "negosyonow") && <>
								&nbsp;&nbsp;&nbsp;
								<Link to={`/organization/negosyonow/`} className={styles.link}>
									(Limited Offer)
								</Link>
							</>}

						</Item.Description>

						<Item.Extra>
							<AddToCart note={itemnote} productId={id} showPopUp={showPrompt} disabled={disabled} loadingProduct={loading}/>
						</Item.Extra>

					</Item.Content>
				</Item>
			</Item.Group>
			<Prompt
				open={showConfirm}
				closePrompt={closePrompt}
				content={{
					content:name+" added to your cart. Thank you!",
					className:`${styles.centerText} ${styles.text}`
				}}
				confirmButtonProps={{
					content:"Proceed to Checkout",
					onClick:()=>{
						navigate('/cart/')
					},
					name:"promptGoToCartButton"
				}}
				cancelButtonProps={{
					content:"Continue Shopping",
					name:"promptContinueShoppingButton"
				}}
				modalProps={{
					onClose:hidePrompt,
					size:"small",
					name:"addToCartPrompt"
				}}

			/>

		</React.Fragment>
	)
}
